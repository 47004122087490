'use strict'

const generateUrl = (email, size)=> {
  const md5 = require('blueimp-md5')
  let url = `https://secure.gravatar.com/avatar/`
  if (isNaN(size)) size = 80
  if (email !== undefined) url += md5(email.trim().toLowerCase())+'?d=mm&r=g&s='+size
  return url
}

const generateHtml = (email, size)=> {
  const url = generateUrl(email, size)
  const html = `<img src="${url}" class="ui tiny circular image" style="max-width: ${size}px;"/>`
  return html
}

export { generateUrl, generateHtml }