'use strict'

const RSVP                = require('es6-promise').Promise
const dayjs               = require('dayjs')
const page                = require('page')

const app                 = require('./app.es6')
const activityUtils       = require('./../components/activity-utils.es6')
const api                 = require('./../components/api.es6')
const access              = require('./../components/access.es6')
const metadata            = require('./../components/metadata.es6')
const reportsDelete       = require('./../components/delete.es6')
const tags                = require('./../components/tags.es6')
const analytics           = require('./../../utils/analytics.es6')
const hubpubsub           = require('./../../utils/hubpubsub.es6')
const views               = require('./../views.es6')

let taskflowId, reportsRefreshSubscription, onlyShowDeleted, reportMetaData, isDateRangePickerEnabled, realtimeChannel
let tasksWithFields = []
let latestTimestamp = 0

export const init = (ctx, next)=> {

  taskflowId      = ctx.params.taskflowId
  onlyShowDeleted = getUrlQueryParameter('onlyShowDeleted', ctx.path, null)
  tasksWithFields = []
  latestTimestamp = 0
  isDateRangePickerEnabled = true
  const trigger   = getUrlQueryParameter('trigger', ctx.path, null)

  if ((app.permissions[taskflowId] !== undefined) && app.permissions[taskflowId].hasAccess) {
    $('#workarea').replaceWith(views.render("reports/table", {
      groupId:        app.groupId,
      permissions:    app.permissions[taskflowId],
      taskflowId:     taskflowId
    }))

    // if you make changes here, also make changes to setupEvents() and onVisible in enableColumnChooserButton()
    configReportMetaData().then( ()=> {
      return configColumnMetaData()
    }).then( ()=> {
      renderHeading()
      apiFetchReport().then( (activities)=> {
        const columns = JSON.parse(localStorage.getItem(`report-columns-${taskflowId}`))
        return activityUtils.prepareActivities(app.groupId, taskflowId, activities, columns, reportMetaData)
      }).then( ([preppedActivities, latestTS])=> {
        if (latestTS > 0) latestTimestamp = latestTS
        return addTableData(preppedActivities)
      }).then( ()=> {
        return ((reportMetaData !== undefined) && (reportMetaData.tags !== undefined)) ? tags.addTagsToTableData(app.groupId, reportMetaData.tags, 'taskflow') : true
      }).then( ()=> {
        const trackingMetadata = { hubUrl: window.location.href }
        analytics.trackEvent('Viewed a live taskflow report', trackingMetadata)
        activateSwitchViewButton()
        activateImporterButton()
        renderDateRangeFilters(isDateRangePickerEnabled)
        return renderColumnChooser()
      }).then( ()=> {
        handleTrigger(trigger)
        return subscribeToRealtimeChannel()
      })
    })

    reportsRefreshSubscription = hubpubsub.subscribe('reports.refresh', (data)=> {
      // Todo: replace with only timestamped return of report result
    })
  } else {
    $('#workarea').replaceWith(views.render("no-access", {}))
  }
}

const configReportMetaData = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    reportMetaData = JSON.parse(localStorage.getItem(`report-${taskflowId}`))
    if (reportMetaData == null || reportMetaData.length == 0) {
      reportMetaData = undefined
    } else {
      if (reportMetaData.ui !== undefined) {
        if (reportMetaData.ui.dateRangePicker !== undefined) isDateRangePickerEnabled = reportMetaData.ui.dateRangePicker
      }
      if (reportMetaData.start !== undefined) {
        if (reportMetaData.start === false) {
          $('#add-new-records-js').addClass('hidden')
        } else {
          if (reportMetaData.start.params !== undefined) {
            const url = $('#add-new-records-js button').data('url')
            $('#add-new-records-js button').data('url', `${url}?${reportMetaData.start.params}`)
          }
          if (reportMetaData.start.autoclose !== undefined) $('#add-new-records-js button').data('autoclose', `${reportMetaData.start.autoclose}`)

          if (reportMetaData.start.target !== undefined) {
            if (reportMetaData.start.target == 'blank-taskflow') {
              $('#add-new-records-js button').data('url', `/hub/group/${app.groupId}/taskflow/new/${taskflowId}/full?${reportMetaData.start.params}`)
              $('#add-new-records-js button').data('target', `blank-taskflow`)
            }
          }
        }
      }
    }
    resolve()
  })
}

const configColumnMetaData = ()=> {
  return new RSVP.Promise((resolve, reject)=> {

    const userMetadata = JSON.parse(localStorage.getItem(`report-columns-${taskflowId}`))
    tasksWithFields = []

    if (userMetadata == null || userMetadata.length == 0) {
      const groupMetadata = JSON.parse(localStorage.getItem(`report-${taskflowId}`))

      if (groupMetadata != null && groupMetadata.columns != null) {
        localStorage.setItem(`report-columns-${taskflowId}`, JSON.stringify(groupMetadata.columns))
        resolve()
      } else {
        api.send(`/api/group/${app.groupId}/tasksWithFieldsReport/${taskflowId}`).then ( (data)=> {
          data = processTasksWithFieldsReportData(data)
          const columns = []
          const max = 3

          if (data.tasksWithFields !== undefined) {
            tasksWithFields = data.tasksWithFields

            tasksWithFields.map( (task)=> {
              if ((task.fields.length > 0) && (columns.length < max)) {

                task.fields.map( (field)=> {
                  if ((columns.length < max) && (field.label != '')) {
                    const style = JSON.parse(field.style)
                    columns.push({
                      'label': field.label,
                      'templateActivityId': task.id,
                      'templateFieldId': field.id,
                      'kind': style.kind
                    })
                  }
                })
              }
            })
            localStorage.setItem(`report-columns-${taskflowId}`, JSON.stringify(columns))
            resolve()
          } else {
            reject('No tasks are available')
          }
        })
      }
    } else {
      resolve()
    }
  })
}

const processTasksWithFieldsReportData = (data)=> {
  data.tasksWithFields.forEach( (task, i)=> {
    data.tasksWithFields[i].icon = task.kind+'.png'
    if (task.kind.indexOf('/') !== -1) {
      data.tasksWithFields[i].icon = task.kind.substring(0, task.kind.indexOf('/'))+'/icon.png'
    }
  })
  return data
}

const apiFetchReport = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    const query = {
      "templateActivityIds": metadata.extractMetadataReportColumns(taskflowId, 'templateActivityId'),
      "templateFieldIds": metadata.extractMetadataReportColumns(taskflowId, 'templateFieldId')
    }

    if (latestTimestamp > 0) {
      query.fromTimestamp = latestTimestamp + 1
    } else {
      if (isDateRangePickerEnabled) {
        const fromTimestamp = localStorage.getItem('date-range-start')
        if (fromTimestamp !== null) query.fromTimestamp = fromTimestamp
      } else {
        query.fromTimestamp = 0
      }
    }
    if (isDateRangePickerEnabled) {
      const toTimestamp   = localStorage.getItem('date-range-end')
      if (toTimestamp !== null) query.toTimestamp = toTimestamp
    }

    if (reportMetaData !== undefined) {
      if (reportMetaData.linkedPermissionFields !== undefined) query.linkedPermissionFields = reportMetaData.linkedPermissionFields
      if (reportMetaData.linkedContactFields !== undefined) query.linkedContactFields = reportMetaData.linkedContactFields
      if (reportMetaData.restrictToGroup !== undefined) query.restrictToGroup = reportMetaData.restrictToGroup
    }
    query.dynamicLookups = true
    if (onlyShowDeleted == 1) query.onlyShowDeleted = true

    api.send(`/hub/reports/${app.groupId}/${taskflowId}`, 'POST', query).then ( (data)=> {
      resolve(data)
    })
  })
}

const addTableData = (tableData)=> {
  return new RSVP.Promise((resolve, reject)=> {
    $('#workarea img.ui.image.centered').hide()

    $.fn.dataTableExt.sErrMode = 'console'

    const metaColumns = JSON.parse(localStorage.getItem(`report-columns-${taskflowId}`))
    let columnsData = [{ title: '' }]
    metaColumns.forEach( (field)=> {
      if (field.kind == 'date') {
        columnsData.push({
          title:    field.label,
          data:     `fields.${field.templateFieldId}`,
          render: ( data, type, row )=> {
            if ((type === 'sort') && (data != '') && (data != '-')) {
              const d = dayjs(data)
              if (d.isValid()) return d.valueOf()
            }
            return data
          }
        })
      } else {
        columnsData.push({
          title:    field.label,
          data:     `fields.${field.templateFieldId}`,
          type:     'natural'
        })
      }
    })

    if (reportMetaData !== undefined) {
      if (reportMetaData.tags !== undefined) {
        columnsData.push({
          title:      'Tags',
          data:       'tags',
          render: ( data, type, row )=> {
            if ((data !== undefined) && (type === 'display')) {
              data = tags.formatter(data)
            }
            return data
          }
        })
      }

      if ((reportMetaData.showAccounts !== undefined) && (reportMetaData.showAccounts)) {
        columnsData.push({
          title:      'Organization/team',
          data:       'group.label'
        })
      }
    }

    columnsData.push({
      title:      'Updated by',
      data:       'user.name'
    })

    columnsData.push({
      title:      'Last updated',
      data:       'timeStamp',
      render: ( data, type, row )=> {
        if (type === 'display' || type === 'filter') return dayjs(parseInt(data)).format("DD MMMM YYYY, HH:mm:ss")
        return data
      }
    })

    columnsData.push({
      title:      '',
      data:       'actions'
    })

    const tbl = $('#datatable').DataTable({
      data:           tableData,
      rowId:          (row)=> { return `${row.processId}` },
      columns:        columnsData,
      scrollY:        '64vh',
      scrollX:        true,
      scrollCollapse: true,
      columnDefs: [
        {
          targets:      0,
          orderable:    false,
          className:    'select-checkbox',
          width:        '10px'
        }
      ],
      select: {
        style:          'multi',
        selector:       'td:first-child',
        info:           false
      },
      paging:         false,
      processing:     true,
      language: {
        search:       'Quickfilter:',
        emptyTable:   '<span class="yellow-strip">No information is available...</span>'
      },
      buttons:        setButtons(),
      destroy:        true   // https://datatables.net/reference/option/destroy
    })

    $('#datatable tr').addClass('top aligned')
    $('#datatable tr td:nth-last-child(2)').addClass('no-wrap')

    let order = [ [ 0, 'asc' ] ]
    order = [ [ columnsData.length - 2, 'desc' ] ]
    tbl.order(order).draw()

    if (app.isMaker) {
      tbl.on('select', (e, dt, type, indexes)=> {
        if (tbl.rows('.selected').ids().length > 0) {
          $('#btn-batch-update-js').removeClass('disabled')
        } else {
          $('#btn-batch-update-js').addClass('disabled')
        }
      })
      tbl.on('deselect', ( e, dt, type, indexes )=> {
        if (tbl.rows('.selected').ids().length > 0) {
          $('#btn-batch-update-js').removeClass('disabled')
        } else {
          $('#btn-batch-update-js').addClass('disabled')
        }
      })
    }

    tbl.buttons().container().prependTo($('.dataTables_filter'), tbl.table().container())

    if (app.permissions[taskflowId].delete) reportsDelete.enableDeleteButton('activities', app.groupId, taskflowId)

    resolve()
  })
}

const setButtons = ()=> {
  const buttons = []

  if (app.permissions[taskflowId].delete) {
    buttons.push({
      text:       '<i class="trash alternate outline fitted icon"></i>&nbsp;Delete',
      className:  'ui button icon red inverted btn-delete-js'
    })
  }

  if ((reportMetaData !== undefined) && (reportMetaData.tags !== undefined)) {
    buttons.push({
      text:       '<i class="tags fitted icon"></i>&nbsp;Tag',
      className:  'ui button icon green inverted btn-tag-js'
    })
  }

  // TODO: combine smart batch update under `More functions` dropdown button with delete and tag
  // if (app.permissions[taskflowId].continue) {
  if (app.isMaker) {
    buttons.push({
      text:       '<i class="icon th list fitted"></i>&nbsp;Batch update',
      className:  'ui button icon green inverted disabled',
      attr:       {
        id:                   'btn-batch-update-js',
        'data-target':        'modal-taskflow-batch',
        'data-url':           `/hub/taskflow/start/${app.groupId}/${taskflowId}/modal`,
        'data-referrer':      `/${app.groupId}/report/${taskflowId}`,
        'data-mode':          'batch',
        'data-taskflowId':    `${taskflowId}`,
        'data-fields':        JSON.stringify({})
      }
    })
  }

  if ((reportMetaData !== undefined) && (reportMetaData.ui !== undefined) && (reportMetaData.ui.customButtons !== undefined)) {
    buttons.push({
      extend:     'csv',
      fieldSeparator: ';',
      text:       '<i class="icon fitted download"></i>&nbsp;Download csv',
      className:  'ui button icon green inverted',
      exportOptions: {
        columns:  ':not(:last-child)',
        format: {
          body: function ( data, row, column, node ) {
            return column === 0 ? node.parentNode.id : data
          }
        }
      }
    })
    
    buttons.push({
      text:       '<i class="icon fitted download"></i>&nbspReport',
      className:  'ui button icon green inverted',
      action: function ( e, dt, node, config ) {
        let fromTimestamp = localStorage.getItem('date-range-start')
        if (fromTimestamp == null) fromTimestamp = 0
        let toTimestamp = localStorage.getItem('date-range-end')
        if (toTimestamp == null) toTimestamp = dayjs().valueOf()
        const url = `${reportMetaData.ui.customButtons}&groupId=${app.groupId}&fromTimestamp=${fromTimestamp}&toTimestamp=${toTimestamp}`
        window.open(url, '_blank')
      }
    })
  } else {
    buttons.push({
      extend:     'csv',
      fieldSeparator: ';',
      text:       '<i class="icon fitted download"></i>&nbsp;Download',
      className:  'ui button icon green inverted',
      exportOptions: {
        columns:  ':not(:last-child)',
        format: {
          body: function ( data, row, column, node ) {
            return column === 0 ? node.parentNode.id : data
          }
        }
      }
    })
  }

  if (app.permissions[taskflowId].customize) {
    buttons.push({
      text:       '<i class="icon fitted table"></i>',
      className:  'ui button icon green inverted btn-column-chooser-js'
    })
  }

  return buttons
}

const renderHeading = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    const activeMenuItem = access.getActiveMenuItem()
    if (activeMenuItem) {
      $('#page-title-js').html(activeMenuItem.title)
      document.title = `${activeMenuItem.title} | Kotive`
    }
    resolve()
  })
}

const renderDateRangeFilters = (isActive)=> {
  return new RSVP.Promise((resolve, reject)=> {
    if (isActive) {
      let start = parseInt(localStorage.getItem('date-range-start'))
      start = (isNaN(start)) ? dayjs().subtract(1, 'month').add(1, 'day').format('MMMM D, YYYY') : dayjs(start).format('MMMM D, YYYY')

      let end   = parseInt(localStorage.getItem('date-range-end'))
      end = (isNaN(end)) ? dayjs().format('MMMM D, YYYY') : dayjs(end).format('MMMM D, YYYY')

      $('#datatable_filter').parent().parent().children().first().html(views.render('reports/date-range', {
        start: start,
        end:   end
      }))

      $('#date-range-start').calendar({
        type: 'date',
        endCalendar: $('#date-range-end'),
        onChange: (date, text, mode)=> {
          const dateRangeStart = dayjs(text, 'MMMM D, YYYY').startOf('day').valueOf()
          localStorage.setItem('date-range-start', dateRangeStart)
          $('#date-range-filter-report-js').show()
        }
      })

      $('#date-range-end').calendar({
        type: 'date',
        startCalendar: $('#date-range-start'),
        onChange: (date, text, mode)=> {
          const dateRangeEnd = dayjs(text, 'MMMM D, YYYY').endOf('day').valueOf()
          localStorage.setItem('date-range-end', dateRangeEnd)
          $('#date-range-filter-report-js').show()
        }
      })
    }
    resolve()
  })
}

const activateImporterButton = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    $('#add-new-records-js .ui.dropdown').dropdown()
    resolve()
  })
}

const activateSwitchViewButton = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    if ((reportMetaData.kanban !== undefined) && (reportMetaData.kanban.length > 0)) {
      $('#switch-view-js').replaceWith(views.render("reports/switch-view", {
        groupId:        app.groupId,
        taskflowId:     taskflowId,
        kanban:         reportMetaData.kanban[0]
      }))

      $('#switch-view-js.ui.dropdown').dropdown()
    }
    resolve()
  })
}

const renderColumnChooser = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    if (app.permissions[taskflowId].customize) {
      enableColumnChooserButton().then( ()=> {
        resolve()
      })
    } else {
      resolve()
    }
  })
}

const enableColumnChooserButton = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    $('.btn-column-chooser-js').popup({
      popup:          '#reusable-popup.popup',
      preserve:       true,
      movePopup:      true,
      exclusive:      true,
      variation:      'wide',
      position:       'bottom right',
      on:             'click',

      onVisible:      ()=> {
        const selectedFields = JSON.parse(localStorage.getItem(`report-columns-${taskflowId}`))
        let selectedColumns = []
        selectedFields.map( (field)=> {
          selectedColumns.push(field.templateFieldId)
        })

        if (tasksWithFields.length == 0) {
          api.send(`/api/group/${app.groupId}/tasksWithFieldsReport/${taskflowId}`).then ( (data)=> {
            data = processTasksWithFieldsReportData(data)

            if (data.tasksWithFields !== undefined) tasksWithFields = data.tasksWithFields
            $('#reusable-popup.ui.popup').html(views.render('reports/column-chooser-popup', {
              tasksWithFields: tasksWithFields,
              selectedColumns: selectedColumns.join(',')
            }))
            $('#column-chooser-dropdown-js').dropdown()
            return true
          })
        } else {
          $('#reusable-popup.ui.popup').html(views.render('reports/column-chooser-popup', {
            tasksWithFields: tasksWithFields,
            selectedColumns: selectedColumns.join(',')
          }))
          $('#column-chooser-dropdown-js').dropdown()
        }

        $('body').on('click', '#reusable-popup .refresh-btn-js', (e)=> {
          e.preventDefault()
          $('#reusable-popup .refresh-btn-js').addClass('loading')

          const selectedColumns = $('#reusable-popup [name="columns"]').val().split(',')
          let columns = []

          tasksWithFields.map( (task)=> {
            if (task.fields.length > 0) {
              task.fields.map( (field)=> {
                if (selectedColumns.indexOf(field.id.toString()) != -1) {
                  const style = JSON.parse(field.style)
                  columns.push({
                    'label': field.label,
                    'templateActivityId': task.id,
                    'templateFieldId': field.id,
                    'kind': style.kind
                  })
                }
              })
            }
          })

          localStorage.setItem(`report-columns-${taskflowId}`, JSON.stringify(columns))
          metadata.updateUserMetadata([{ key: `report-columns-${taskflowId}`, value: JSON.stringify(columns), valueType: 'json' }]).then( ()=> {
            $('.btn-column-chooser-js').popup('hide')

            setTimeout( ()=> {
              page(window.location.hash.substr(2))
            }, 750)
          })
        })

        $('body').on('click', '#reusable-popup .close-popup', (e)=> {
          $('.btn-column-chooser-js').popup('hide')
        })
      },

      onHidden:       ()=> {
        $('body').off('click', '#reusable-popup .refresh-btn-js')
        $('body').off('click', '#reusable-popup .close-popup')
        $('#reusable-popup.ui.popup').html(views.render('components/empty-popup', {}))
      }
    })
    resolve()
  })
}

const handleTrigger = (trigger)=> {
  return new RSVP.Promise((resolve, reject)=> {
    // a trigger should always be in form e.g. 'trigger=history-1234-12345' (${action}-${groupId}-${processId})
    if (trigger != null) {
      const parts = trigger.split('-')
      if ((parts[0] == 'history') && (parts.length == 3)) {
        $("[data-target='modal-taskflow-history']").trigger('click', `${parts[1]}-${parts[2]}`)
      }
      resolve()
    } else {
      resolve()
    }
  })
}

const subscribeToRealtimeChannel = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    let isBusy = false
    realtimeChannel = app.realtimePubSub.channels.get(`taskflows:${app.groupId}:${taskflowId}`)

    realtimeChannel.subscribe('updated', (message)=> {
      if (!isBusy) {
        isBusy = true
        apiFetchReport().then( (activities)=> {
          const columns = JSON.parse(localStorage.getItem(`report-columns-${taskflowId}`))
          return activityUtils.prepareActivities(app.groupId, taskflowId, activities, columns, reportMetaData)
        }).then( ([preppedActivities, latestTS])=> {
          if (latestTS > 0) latestTimestamp = latestTS
          return updateTableData(preppedActivities)
        }).then( ()=> {
          isBusy = false
          setTimeout( ()=> { $('tr.refreshed').removeClass('refreshed') }, 3000)
          return
        })
      }
    })
    resolve()
  })
}

const updateTableData = (tableData)=> {
  return new RSVP.Promise((resolve, reject)=> {
    const tbl = $('#datatable').DataTable()
    tableData.map( (rowData)=> {
      if ($(`#${rowData.processId}`).length > 0) {
        tbl.row(`#${rowData.processId}`).data(rowData)
      } else {
        tbl.row.add(rowData).draw()
      }
      $(`#${rowData.processId}`).addClass('refreshed')
    })
    resolve()
  })
}

const unsubscribeFromRealtimeChannel = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    realtimeChannel.unsubscribe(`updated`)
    resolve()
  })
}

const getUrlQueryParameter = (name, url, defaultValue)=> {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  defaultValue = (defaultValue !== undefined) ? defaultValue : ''
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(url)
  return (results === null) ? defaultValue : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const setupEvents = ()=> {
  $('body').on('click', "#date-range-filter-report-js button", (e)=> {
    e.preventDefault()
    $('#date-range-filter-report-js button').addClass('loading')
    setTimeout( ()=> {
      page(window.location.hash.substr(2))
    }, 500)
  })

  $('body').on('click', ".retry-taskflow-js", (e)=> {
    e.preventDefault()
    $(e.target).replaceWith(`<img class="ui spaced image" src="${require('../../../images/loading.gif')}"/>`)
    api.send(e.target.href)
  })
}

export const onPageExit = (ctx, next)=> {
  $('.btn-column-chooser-js').popup('hide')
  if ((app.permissions[taskflowId] !== undefined) && app.permissions[taskflowId].hasAccess) {
    $('#reusable-popup.popup').detach().appendTo('body')
    reportsRefreshSubscription.remove()
    unsubscribeFromRealtimeChannel()
  }
  next()
}
