'use strict'

const RSVP                = require('es6-promise').Promise
const dayjs               = require('dayjs')

const app                 = require('./../modules/app.es6')
const activityUtils       = require('./../components/activity-utils.es6')
const api                 = require('./../components/api.es6')
const gravatar            = require('./../components/gravatar.es6')
const analytics           = require('./../../utils/analytics.es6')
const hubpubsub           = require('./../../utils/hubpubsub.es6')
const modalComponent      = require('./../../utils/modal.es6')
const views               = require('./../views.es6')

let groupId, taskflowId, processId
let auditType  = 'audits'

export const showHistoryInFull = (ctx, next)=> {
  groupId      = ctx.params.groupId
  taskflowId   = ctx.params.taskflowId
  processId    = ctx.params.processId
  auditType    = ((ctx.params.auditType !== undefined) && (ctx.params.auditType == 'full')) ? 'historical' : 'audits'

  showHistory(true).then( ()=> {
  }).catch( (err)=> {
    console.log(err)
  })
}
const showHistory = (isStandalone)=> {
  return new RSVP.Promise((resolve, reject)=> {
    renderHistory(isStandalone).then( ()=> {
      return apiFetchHistory()
    }).then( (records)=> {
      return renderHistory(isStandalone, records)
    }).then( ()=> {
      const userIds = []
      $('.timeline-gravatar').each( (i, u)=> {
        const id = $(u).prop('class')
        const userid = parseInt(id.substring(id.lastIndexOf('-')+1))
        if (userIds.indexOf(userid) === -1) userIds.push(userid)
      })
      return apiFetchUsers(userIds)
    }).then( (users)=> {
      return updateUserProfiles(users)
    }).then( ()=> {
      const trackingMetadata = { groupId: app.groupId, taskflowId: taskflowId, processId: processId }
      analytics.trackEvent('Viewed taskflow history', trackingMetadata)
      resolve()
    }).catch( (err)=> {
      reject(err)
    })
  })
}

const renderHistory = (isStandalone, records)=> {
  return new RSVP.Promise((resolve, reject)=> {
    if (isStandalone) {
      $('body').addClass('print')
      $('#navigation-menu').hide()

      $('#workarea').replaceWith(views.render("taskflow-history/history-container", {
        groupId:        app.groupId,
        permissions:    app.permissions[taskflowId],
        taskflowId:     taskflowId,
        processId:      processId,
        loadingHistory: (records !== undefined) ? false : true,
        records:        records,
        isStandalone:   isStandalone
      }))

    } else {
      $('#reusable-modal .content').replaceWith(views.render("taskflow-history/history-container", {
        groupId:        app.groupId,
        permissions:    app.permissions[taskflowId],
        taskflowId:     taskflowId,
        processId:      processId,
        loadingHistory: (records !== undefined) ? false : true,
        records:        records,
        isStandalone:   isStandalone
      }))

      modalComponent.resizeHeight('#reusable-modal')
    }
    resolve()
  })
}

const apiFetchHistory = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    const includeFields = [
      'timeStamp',
      'activityData.status',
      'activityData.id',
      'activityData.processId',
      'activityData.label',
      'activityData.templateActivityId',
      'activityData.fields.id',
      'activityData.fields.label',
      'activityData.fields.value',
      'activityData.fields.pattern',
      'activityData.fields.templateFieldId',
      'activityData.fields.required',
      'activityData.fields.style',
      'userId',
      'activityData.kind',
      'activityData.serviceResponses.key',
      'activityData.serviceResponses.value',
    ].join(',')

    const recordsLimit = 10000

    const query = {
      "filtered": {
        "filter": {
          "bool": {
            "must": {
              "term": {
                "activityData.processId": parseInt(processId)
              }
            },
            "must_not": {
              "term": {
                "activityData.templateActivityId": parseInt(taskflowId)
              }
            }
          }
        }
      },
      "sort": [
        {
          "timeStamp": "asc"
        }
      ]
    }

    api.send(`/hub/history/${app.groupId}/${taskflowId}/${auditType}/activity?includeFields=${encodeURIComponent(includeFields)}&size=${recordsLimit}`, 'POST', query).then( (data)=> {
      let sortedRecords = []
      const optionKinds = ['check', 'radio', 'select']

      if (data.hits.hits.length > 0) {
        const records = data.hits.hits.map( (r)=> {
          let record = r._source
                    
          record.cloneTaskData = [
            {
              uri: `/group/${app.groupId}/processes/${taskflowId}`,
              payload: [
                {
                  id: -1,
                  id_process: 0,
                  id_owning_process: -1,
                  id_t: parseInt(taskflowId),
                  fields: []
                }
              ]
            }
          ]

          // add icon path information
          const kind = record.activityData.kind
          const iconPath = (kind == 'form') ? 'form' : `${kind.split('/')[0]}/icon`
          record.icon = `/images/icons/${iconPath}.png`

          // create date/time string for display
          const dateStamp = dayjs.unix(record.timeStamp / 1000)
          record.date = dateStamp.format('ddd, D MMM YYYY')
          record.time = dateStamp.format('HH:mm:ss (Z)')

          // service responses
          if (record.activityData.serviceResponses) {
            // record.responseSuccess = false
            record.activityData.serviceResponses.forEach( (sr)=> {
              if ((sr.key == 'kotive_request_status') && (sr.value == 'success')) {
                record.responseSuccess = true
              }
            })
          }

          record = activityUtils.processHistoryActivity(app.groupId, record)

          return record
        })
        sortedRecords = _.sortBy(records, 'timeStamp')
      }
      resolve(sortedRecords)
    })
  })
}

const apiFetchUsers = (userIds)=> {
  return new RSVP.Promise((resolve, reject)=> {
    api.send(`/api/user/group/${app.groupId}/users?ids=${userIds.join(',')}`).then( (data)=> {
      let users = data.users.map( (u)=> {
        const displayName = `${u.firstname} ${u.lastname}`
        u.displayName = (displayName == '') ? u.email : displayName
        return u
      })
      resolve(users)
    })
  })
}

const updateUserProfiles = (users)=> {
  return new RSVP.Promise((resolve, reject)=> {
    users.forEach( (user, i)=> {
      $(`.timeline-user-${user.id}`).html(views.render("taskflow-history/timeline-user", {
        user: user,
        gravatar: gravatar.generateUrl(user.email, 80)
      }))
    })
    resolve()
  })
}

export const setupEvents = ()=> {
  $('body').on('click', "[data-target='modal-taskflow-history']", (e, opts)=> {
    e.preventDefault()

    // e.g. opts = '1581-13323' (for being manually triggered)
    const params  = (opts !== undefined) ? opts.split('-') : e.currentTarget.href.substring(e.currentTarget.href.lastIndexOf('/')+1).split('-')
    if (params.length == 2) {
      taskflowId  = parseInt(params[0])
      processId   = parseInt(params[1])

      $('#reusable-modal').modal({
        duration: 10,
        selector: {
          deny:      '.close-modal, .actions .close-modal'
        },
        onShow: ()=> {
          $('#reusable-modal').addClass('fullscreen').removeClass('small')
          $('#reusable-modal .actions').prepend(views.render("taskflow-history/history-modal-actions", {
            groupId:    app.groupId,
            taskflowId: taskflowId,
            processId:  processId
          }))
          $('#reusable-modal .actions .ui.button.positive').hide()
          $('#reusable-modal .actions .ui.button.close').html('Close')
        },
        onVisible: ()=> {
          $('#reusable-modal .header').html('History')
          showHistory().then( ()=> {
          }).catch( (err)=> {
            console.log(err)
          })
        },
        onHidden: ()=> {
          $('#reusable-modal').addClass('small').removeClass('fullscreen')
          $('#reusable-modal .content').removeClass('timeline-content')
          $('#reusable-modal .actions .history-modal-actions-js').remove()
          $('#reusable-modal .actions .ui.button.close').html('Don\'t save')

          modalComponent.revertUI('#reusable-modal')
        }
      }).modal('show').modal('refresh')
    }
  })
}

export const onPageExit = (ctx, next)=> {
  $('body').removeClass('print')
  $('#navigation-menu').show()
  next()
}
