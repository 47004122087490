'use strict'

const RSVP        = require('es6-promise').Promise
const dayjs       = require('dayjs')

const app         = require('./../modules/app.es6')
const api         = require('./../components/api.es6')
const files       = require('./../../utils/files.es6')
const patterns    = require('./../../utils/patterns.es6')
const views       = require('./../views.es6')

export const prepareActivities = (groupId, taskflowId, activities, columns, reportMetaData)=> {
  return new RSVP.Promise((resolve, reject)=> {
    let continueTitle = 'Continue&nbsp;&rarr;'
    let retryTitle = 'Retry?'
    let continueTarget = 'modal-taskflow'
    let autoclose = false
    let historyTitle = 'View history'

    if (reportMetaData !== undefined) {
      if (reportMetaData.continue !== undefined) {
        if (reportMetaData.continue.title !== undefined) continueTitle = reportMetaData.continue.title
        if (reportMetaData.continue.retryTitle !== undefined) retryTitle = reportMetaData.continue.retryTitle
        if (reportMetaData.continue.target !== undefined) continueTarget = reportMetaData.continue.target
        if (reportMetaData.continue.autoclose !== undefined) autoclose = reportMetaData.continue.autoclose
      }
      if (reportMetaData.history !== undefined) {
        if (reportMetaData.history.title !== undefined) historyTitle = reportMetaData.history.title
      }
    }

    let latestTimestamp = 0

    const preppedActivities = activities.map( (record)=> {
      if (record.timeStamp > latestTimestamp) latestTimestamp = record.timeStamp
      record.humanDate = dayjs(record.timeStamp).format('D MMM YYYY')
      record.actions = []

      if (app.permissions[taskflowId] !== undefined) {
        if (app.permissions[taskflowId].audits) {
          record.actions.push(`<a href="${taskflowId}-${record.processId}" title="View the full history" data-target="modal-taskflow-history">${historyTitle}</a>`)
        }

        if (app.permissions[taskflowId].continue) {
          if (record.status == 'failed') {
            record.actions.push(`<a href="/api/group/${record.groupId}/process/${record.processId}/retry" title="Retry the failed task" class="retry-taskflow-js">${retryTitle}</a>`)
          } else if (record.phase != 'done') {
            const continueUrl = ((reportMetaData !== undefined) && (reportMetaData.continue !== undefined) && (reportMetaData.continue.url !== undefined)) ? reportMetaData.continue.url.replace(/\${groupId}/mg, record.groupId).replace(/\${taskflowId}/mg, taskflowId).replace(/\${processId}/mg, record.processId) : `/hub/taskflow/continue/${record.groupId}/${record.processId}/modal`
            record.actions.push(`<a href="${continueUrl}" title="Continue..." data-target="${continueTarget}" data-referrer="/${groupId}/report/${taskflowId}/" ${autoclose ? 'data-autoclose="true"' : ''}>${continueTitle}</a>`)
          } else if (record.phase == 'done') {
            record.actions.push(`<i class="icon check green"></i>`)
          }
        }
      }
      record.actions = record.actions.join('&nbsp;&nbsp;')

      if (columns !== undefined) {
        // uploads
        columns.filter( c => (c.kind === 'upload' || c.kind === 'uploadshow') ).map( (column)=> {
          if ((record.fields[column.templateFieldId] !== undefined) && (record.fields[column.templateFieldId] !== '') && (record.fields[column.templateFieldId] !== '-')) {
            record.fields[column.templateFieldId] = views.render("components/display-uploads", {
              uploads: JSON.parse(record.fields[column.templateFieldId])
            })
          }
        })

        // profilelink
        columns.filter( c => (c.kind === 'profilelink') ).map( (column)=> {
          if ((record.fields[column.templateFieldId] !== undefined) && (record.fields[column.templateFieldId] !== '') && (record.fields[column.templateFieldId] !== '-')) {
            record.fields[column.templateFieldId] = `<a href="/hub/${record.groupId}/profiles/${taskflowId}/all/flow/${record.processId}">${record.fields[column.templateFieldId]}</a>`
          }
        })
      }
      return record
    })
    resolve([preppedActivities, latestTimestamp])
  })
}

export const reorderFieldsInActivities = (columns, activities)=> {
  return new RSVP.Promise((resolve, reject)=> {
    const orderedColumnIds = columns.map( (column)=> {
      return column.templateFieldId
    })
    const reorderedActivities = activities.map( (record)=> {
      const orderedFields = orderedColumnIds.map( (id)=> {
        return (record.fields[id] !== undefined) ? record.fields[id] : ''
      })
      record.fields = orderedFields
      return record
    })
    resolve(reorderedActivities)
  })
}

export const processHistoryActivity = (groupId, record)=> {
  const kind = record.activityData.kind
  const optionKinds = ['check', 'radio', 'select']

  /* Exceptions to the rule for fields - START */
  if (kind == 'form') {
    // eval field.style.kind
    // todo: ajax load thumbnails of uploaded files for files-upload and files-retrieve fields
    // linkedTaskflows fields - links launch more 'history' modals
    // lookup other users if selected from dropdown. Show person's gravatar + link to profile
    record.activityData.fields.forEach( (field, i)=> {
      record.cloneTaskData[0].payload[0].fields.push({
        id: `-1_${record.activityData.templateActivityId}_${field.templateFieldId}`,
        value: field.value
      })

      if (field.pattern.match(/((sse\[)|(mse\[))+([0-9]+:)/g)) {
        field.pattern = patterns.extractFieldPattern(field.pattern)
        record.activityData.fields[i].value = field.value.split('^').map((v)=> {
          const found = field.pattern.selections.find((opt)=> {
            return opt.key == v
          })
          return (found !== undefined) ? `<span title='id: ${found.key}'>${found.value}</span>` : v
        }).join(', ')
      } else if (field.pattern.match(/(\^)/g)) {
        record.activityData.fields[i].value = field.value.split('^').join(', ')
      }
    })
  } else if (kind == 'docraptor/new_document') {
    record.activityData.fields.forEach( (field, i)=> {
      if (field.label == 'Document content') {
        record.activityData.fields.splice(i, 1)
      }
    })
  } else if (kind == 'googledrive3/export_file') {
    record.activityData.serviceResponses.forEach( (serviceResponse, i)=> {
      if (serviceResponse.key == 'filecontent') {
        record.activityData.serviceResponses[i].value = '<span class="yellow-strip" style="margin-left: 0px;">The file\'s content (base64 encoded) has been hidden for display purposes...</span>'
      }
    })
  } else if (kind == 'sparkpost/send_email_with_attachment') {
    record.activityData.fields.forEach( (field, i)=> {
      if (field.label == 'Attachment\'s content') {
        record.activityData.fields[i].value = '<span class="yellow-strip" style="margin-left: 0px;">The attachment\'s content (base64 encoded) has been hidden for display purposes...</span>'
      }
    })
  } else if (kind == 'sparkpost/send_email_with_substitution_data') {
    record.activityData.fields.forEach( (field, i)=> {
      if ((field.label == 'Message') && (record.activityData.fields[i].value.length >= 32000)) {
        record.activityData.fields[i].value = '<span class="yellow-strip" style="margin-left: 0px;">The message\'s content has been hidden because it\'s too long...</span>'
      }
    })
  }
  return record
}
