'use strict'

const RSVP        = require('es6-promise').Promise

const api         = require('./../components/api.es6')

const getAvailableRoles = (groupId, forceRefresh)=> {
  return new RSVP.Promise((resolve, reject)=> {
    const personProfileGroupId = localStorage.getItem('group-roles-group')

    let roles = []

    if ((personProfileGroupId != groupId) || (forceRefresh)) {
      localStorage.removeItem('group-roles')

      api.send(`/api/group/${groupId}/roles`).then( (data)=> {
        localStorage.setItem('group-roles-group', groupId)
        roles = data.roles
        localStorage.setItem('group-roles', JSON.stringify(roles))
        resolve(roles)
      })
    } else {
      roles = JSON.parse(localStorage.getItem('group-roles'))
      resolve(roles)
    }
  })
}

const getPersonsRoles = (groupId, personId, forceRefresh)=> {
  return new RSVP.Promise((resolve, reject)=> {
    api.send(`/api/group/${groupId}/personRoles?personId=${personId}`).then( (personRoles)=> {
      getAvailableRoles(groupId, forceRefresh).then( (allRoles)=> {
        const selectedRoles = personRoles.personRoles.map( (pr)=> {
          const roleDetails = _.find(allRoles, ( (ar)=> {
            return pr.roleId == ar.roleId
          }))
          if (roleDetails !== undefined) pr.label = roleDetails.label
          return pr
        })
        resolve(selectedRoles)
      })
    })
  })
}

export { getAvailableRoles, getPersonsRoles }