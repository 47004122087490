'use strict'

const page        = require('page')
const RSVP        = require('es6-promise').Promise

const api         = require('./../components/api.es6')
const analytics   = require('./../../utils/analytics.es6')
const hubpubsub   = require('./../../utils/hubpubsub.es6')
const views       = require('./../views.es6')

export const enableArchiveButton = (kind, baseGroupId, groupId, personId, metadata)=> {
  $('.btn-archive-js').popup({
    popup:          '#reusable-popup.popup',
    preserve:       true,
    movePopup:      true,
    exclusive:      true,
    variation:      'wide',
    position:       'bottom center',
    on:             'click',

    onShow:         ()=> {
      let popupView = (kind == 'person') ? 'people/archive-person-popup' : ''
      $('#reusable-popup.ui.popup').html(views.render(popupView, {
        metadata: JSON.parse(metadata)
      }))
    },

    onVisible:      ()=> {
      $('#reusable-popup .archive-btn-js').on('click', (e)=> {
        e.preventDefault()

        $('#reusable-popup .archive-btn-js').addClass('loading')
        if (kind == 'person') {
          apiArchivePerson(groupId, personId)
          // close popup, don't wait for completion
          setTimeout( ()=> {
            $('.btn-archive-js').popup('hide')
            page(`/${baseGroupId}/people/${groupId}`)
          }, 1500)
        }
      })

      $('#reusable-popup .close-popup').on('click', (e)=> {
        $('.btn-archive-js').popup('hide')
      })
    },

    onHidden:       ()=> {
      $('#reusable-popup .archive-btn-js').off('click')
      $('#reusable-popup .close-popup').off('click')
      $('#reusable-popup.ui.popup').html(views.render('components/empty-popup', {}))
    }
  })
}

const apiArchivePerson = (groupId, personId)=> {
  return new RSVP.Promise((resolve, reject)=> {
    const trackingMetadata = { groupId: groupId, personId: personId }
    analytics.trackEvent('Archived person', trackingMetadata)
    hubpubsub.publish('notification.show', { kind: 'green', message: 'The person\'s profile has been queued for archiving and will be removed once archived. You can continue using the app since this may take a few minutes.'})
    
    api.send(`/hub/export/reports/person/${groupId}/${personId}?isArchive=true`)

    resolve()
  })
}