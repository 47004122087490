'use strict'

const RSVP        = require('es6-promise').Promise

const views       = require('./../views.es6')

export const renderExternalWidget = (component)=> {
  return new RSVP.Promise((resolve, reject)=> {
    component.url = component.url.replace(/\${reloadId}/g, component.divId)
    $.ajax({
      url:            component.url,
      method:         'POST',
      data:           component.data,
      contentType:    'application/json; charset=utf-8',
      dataType:       'html',
      cache:          false,
      beforeSend:     (xhr)=> {
        xhr.setRequestHeader('Authorization', 'Basic ' + btoa(localStorage.getItem('user-email')+':'+localStorage.getItem('auth-token')))
      }
    }).done( (data, textStatus, jqxhr)=> {
      const view = (component.layout == 'widgetTable') ? 'table' : 'card'
      $(`#widget-${component.divId}`).replaceWith(views.render(`widgets/${view}`, {
        id:                component.label.replace(' ','-'),
        component:         component,
        html:              data
      }))
      resolve()
    }).fail( (jqxhr, textStatus, errorThrown)=> {
      const error = `Cannot process request [${jqxhr.status}] (${textStatus}): ${errorThrown}. ${jqxhr.responseText}`
      console.error(error)
      resolve()
    })
  })
}

export const refreshBubbleChartData = (config, data)=> {
  return new RSVP.Promise((resolve, reject)=> {
    // const chart = new Chart($(config.selector), {
    //   type: 'bubble',
    //   data: {
    //     labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    //     datasets: [{
    //         label: '# of Votes',
    //         data: [
    //           { "x":40, "y":10, "r":5 },
    //           { "x":20, "y":30, "r":10 },
    //           { "x":24, "y":29, "r":15 },
    //           { "x":3, "y":32, "r":20 },
    //           { "x":63, "y":12, "r":25 },
    //           { "x":39, "y":13, "r":20 },
    //           { "x":32, "y":17, "r":15 },
    //           { "x":14, "y":40, "r":10 }
    //         ],
    //         backgroundColor: "#9966FF",
    //         hoverBackgroundColor: "#000000",
    //         hoverBorderColor: "#9966FF",
    //         hoverBorderWidth: 5,
    //         hoverRadius: 5
    //     }]
    //   },
    //   options: {
    //     legend: {
    //       position: 'bottom',
    //       onClick: ()=> {}
    //     }
    //   }
    // })
    // console.log(Chart.defaults.bubble)

    resolve()
  })
}

export const setupEventsBubble = (config)=> {
  return new RSVP.Promise((resolve, reject)=> {
    resolve()
  })
}

export const renderMatrixTable = (config)=> {
  return new RSVP.Promise((resolve, reject)=> {
    const matrix  = {}
    const yId     = config.options.yAxis.fieldId
    const xId     = config.options.xAxis.fieldId
    let yi = 0
    let xi = 0

    _.each(config.options.yAxis.labels, (y)=> {
      matrix[y] = []
      _.each(config.options.xAxis.labels, (x)=> {
        matrix[y][x] = {
          'y': y,
          'x': x,
          color: config.options.colors[yi][xi]
        }
        xi += 1
      })
      yi += 1
      xi = 0
    })

    $(`#${config.selector}`).replaceWith(views.render("widgets/matrix", {
      config:         config,
      matrix:         matrix
    }))
    resolve()
  })
}

export const refreshMatrixData = (config, data)=> {
  return new RSVP.Promise((resolve, reject)=> {
    config.options.sizes = ["mini", "tiny", "small", "medium", "large", "big", "huge", "massive"]
    config.options.ratio = Math.ceil(data.length/config.options.sizes.length)
    config.options.maxCount = 0
    const matrix = {}
    let yi = 0
    let xi = 0

    _.each(config.options.yAxis.labels, (y)=> {
      matrix[y] = {}
      _.each(config.options.xAxis.labels, (x)=> {
        matrix[y][x] = 0
        xi += 1
      })
      yi += 1
      xi = 0
    })

    _.each(data, (d)=> {
      if (matrix[d.fields[config.options.yAxis.fieldId]] !== undefined) {
        if (matrix[d.fields[config.options.yAxis.fieldId]][d.fields[config.options.xAxis.fieldId]] !== undefined) {
          matrix[d.fields[config.options.yAxis.fieldId]][d.fields[config.options.xAxis.fieldId]] += 1
          if (config.options.maxCount < matrix[d.fields[config.options.yAxis.fieldId]][d.fields[config.options.xAxis.fieldId]]) config.options.maxCount = matrix[d.fields[config.options.yAxis.fieldId]][d.fields[config.options.xAxis.fieldId]]
        }
      }
    })
    config.options.ratio = Math.ceil(config.options.maxCount/config.options.sizes.length)

    _.each(matrix, (vY, kY)=> {
      _.each(matrix[kY], (vX, kX)=> {
        let cell = ''
        if (matrix[kY][kX] > 0) {
          const color = $(`#${config.selector} [data-composite='${kY}_${kX}']`).data('color')
          const size = config.options.sizes[Math.ceil(matrix[kY][kX]/config.options.ratio)-1]
          cell = `<a class="ui circular label ${color} ${size}">${matrix[kY][kX]}</a>`
        }
        $(`#${config.selector} [data-composite='${kY}_${kX}']`).html(cell)
      })
    })

    resolve()
  })
}

export const setupEventsMatrix = (config)=> {
  return new RSVP.Promise((resolve, reject)=> {
    $('body').on('click', `#${config.selector} .matrix-all a.circular`, (e)=> {
      e.preventDefault()
      $(`#${config.selector} .matrix-table`).removeClass('matrix-all').addClass('matrix-filtered')

      const cell = $(e.target).parent()
      cell.addClass('matrix-active')
      const y    = cell.data('y')
      const x    = cell.data('x')

      const tbl = $('#datatable').DataTable()

      tbl.columns(config.options.xAxis.columnIndex).search(`(^${x}*$)`, true, false).columns(config.options.yAxis.columnIndex).search(`(^${y}*$)`, true, false).draw()
    })

    $('body').on('click', `#${config.selector} .matrix-filtered`, (e)=> {
      $(`#${config.selector} .matrix-active`).removeClass('matrix-active')
      $(`#${config.selector} .matrix-table`).removeClass('matrix-filtered').addClass('matrix-all')

      const tbl = $('#datatable').DataTable()
      tbl.columns(config.options.xAxis.columnIndex).search('').columns(config.options.yAxis.columnIndex).search('').draw()
    })
  })
}

export const refreshProgressScoringData = (config, data)=> {
  return new RSVP.Promise((resolve, reject)=> {
    const scoringTotals = { maxScore: 0, score: 0, percentage: 0 }
    const scoringBuckets = Object.assign({}, config.options.scoringBuckets)
    const scoringBucketKeys = Object.keys(scoringBuckets)
    _.each(scoringBucketKeys, (key)=> {
      scoringBuckets[key] = {}
    })

    _.each(data, (d)=> {
      let value = ''
      if ((d.fields[config.options.mapping.scoringValues.x] !== undefined) && (d.fields[config.options.mapping.scoringValues.x] != '') && (d.fields[config.options.mapping.scoringValues.y] !== undefined) && (d.fields[config.options.mapping.scoringValues.y] != '')) {
        value = config.options.scoringValues[d.fields[config.options.mapping.scoringValues.x]][d.fields[config.options.mapping.scoringValues.y]]
        if (isNaN(parseInt(value)) === false) scoringTotals.score += parseInt(value)
      }
      if (value != 'n/a') scoringTotals.maxScore += 100
      if (scoringTotals.maxScore > 0) scoringTotals.percentage = Math.round(scoringTotals.score/scoringTotals.maxScore*100)

      _.each(scoringBucketKeys, (key)=> {
        if (d.fields[key] !== undefined) {
          if (scoringBuckets[key][d.fields[key]] === undefined) scoringBuckets[key][d.fields[key]] = { maxScore: 0, score: 0, percentage: 0 }
          if (value != 'n/a') scoringBuckets[key][d.fields[key]].maxScore += 100
          if (isNaN(parseInt(value)) === false) scoringBuckets[key][d.fields[key]].score += parseInt(value)
          if (scoringBuckets[key][d.fields[key]].maxScore > 0) scoringBuckets[key][d.fields[key]].percentage = Math.round(scoringBuckets[key][d.fields[key]].score/scoringBuckets[key][d.fields[key]].maxScore*100)
        }
      })
    })
    resolve({
      scoringTotals: scoringTotals,
      scoringBuckets: scoringBuckets
    })
  })
}

export const renderProgressScoringView = (assessment, config, progressScoringData)=> {
  return new RSVP.Promise((resolve, reject)=> {
    const progressBars = []
    _.each(config.options.progressBars, (barV, barK)=> {
      if (barV == 'Overall progress') {
        progressBars.push({
          label: 'Overall progress',
          percentage: progressScoringData.scoringTotals.percentage,
          size: 'large'
        })
      } else {
        _.each(barV, (b)=> {
          let percentage = 0
          if (progressScoringData.scoringBuckets[barK][b] !== undefined) {
            percentage = progressScoringData.scoringBuckets[barK][b].percentage
          }
          progressBars.push({
            label: b,
            percentage: percentage,
            size: 'small'
          })
        })
      }
    })

    let assessmentRules
    const rulesKeys = Object.keys(config.rules)
    if (rulesKeys.length > 0) {
      if ((config.rules[rulesKeys[0]] !== undefined) && (assessment.ratedInAssessment !== undefined) && (config.rules[rulesKeys[0]][assessment.ratedInAssessment] !== undefined)) {
          assessmentRules = config.rules[rulesKeys[0]][assessment.ratedInAssessment]
      }
    }
    console.log(assessment)
    $(`#${config.selector}`).replaceWith(views.render("widgets/progressScoring", {
      config:                      config,
      assessmentRules:             assessmentRules,
      progressBars:                progressBars,
      progressScoringData:         progressScoringData,
      assessment:                  assessment
    }))
    resolve()
  })
}
export const onPageExit = (configList)=> {
  return new RSVP.Promise((resolve, reject)=> {
    _.each(configList, (config)=> {
      if (config.type == 'matrix') {
        $('body').off('click', `#${config.selector} .matrix-all a.circular`)
        $('body').off('click', `#${config.selector} .matrix-filtered`)
      }
    })
    resolve()
  })
}