'use strict'

const hubpubsub   = require('./../../utils/hubpubsub.es6')
const views       = require('./../views.es6')

const setupEvents = ()=> {

  const notificationSubscription = hubpubsub.subscribe('notification.show', (data)=> {
    const id = Math.round(Math.random()*100)
    data.hide = (data.hide !== false) ? ((isNaN(data.hide)) ? 7500 : data.hide * 1000) : false
    data.kind = (data.kind !== undefined) ? data.kind : 'info'
    data.close = (data.close !== undefined) ? data.close : true

    $('#reusable-notifications').append(views.render('components/notification', {
      id:         id,
      kind:       data.kind,
      message:    data.message,
      close:      data.close
    }))

    if (data.hide !== false) {
      setTimeout( ()=> {
        $(`#notification-${id}`).remove()
      }, data.hide)
    }
  })

  $('body').on('click', "#reusable-notifications .close", (e)=> {
    e.preventDefault()
    $(e.target).parent().remove()
  })
}

export { setupEvents }