'use strict'

const RSVP                = require('es6-promise').Promise
const page                = require('page')

const app                 = require('./app.es6')
const access              = require('./../components/access.es6')
const api                 = require('./../components/api.es6')
const groupUtils          = require('./../components/group-utils.es6')
const views               = require('./../views.es6')

export const init = (ctx, next)=> {
  if (ctx.params.id !== undefined) {
    $('#navigation-menu').replaceWith(views.render('navigation', {
      isLoading: true
    }))
    $('#workarea').replaceWith(`<div id="workarea"><img src="${require('../../../images/loading.gif')}" class="ui centered image"/></div>`)
    app.switchAccount(ctx.params.id).then( ()=> {
      return access.renderNavigation()
    }).then( ()=> {
      page('/hub/')
    }).catch( (err)=> {
      console.log(err)
    })
  } else {
    document.title = 'Switch to another account... | Kotive'
    showPage().then( ()=> {
    }).catch( (err)=> {
      console.log(err)
    })
  }
}

const showPage = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    renderCanvas().then( ()=> {
      return groupUtils.apiFetchAllGroupsForUser()
    }).then( (grps)=> {
      const groupedGroups = _.groupBy(_.sortBy(grps, 'label'), ( (g)=> { return g.id_parent }))
      return renderSwitchBranches(grps, groupedGroups)
    }).then( ()=> {
      resolve()
    }).catch( (err)=> {
      reject(err)
    })
  })
}

const renderCanvas = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    $('#navigation-menu').hide()
    $('#workarea').replaceWith(views.render("switcher/switcher", {}))
    resolve()
  })
}

const renderSwitchBranches = (allGroups, groupedGroups)=> {
  return new RSVP.Promise((resolve, reject)=> {
    let baseGroups = []

    _.each(groupedGroups, (grp, key)=> {
      const found = _.findWhere(allGroups, { id: parseInt(key) } )
      if (found === undefined) baseGroups = baseGroups.concat(groupedGroups[key])
    })

    let depth = 0
    $('#branches-loading').remove()
    _.each(baseGroups, (branch)=> {
      renderBranch(branch, depth, groupedGroups)
    })
    resolve()
  })
}

const renderBranch = (branch, depth, groups)=> {
  return new RSVP.Promise((resolve, reject)=> {
    $('#branches').append(views.render("switcher/branch", {
      groupId:  app.groupId,
      branch:   branch,
      depth:    depth
    }))

    if (groups[branch.id] !== undefined) {
      depth++
      _.each(groups[branch.id], (subBranch)=> {
        renderBranch(subBranch, depth, groups)
      })
    }
    resolve()
  })
}

export const onPageExit = (ctx, next)=> {
  $('#navigation-menu').show()
  next()
}