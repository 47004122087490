'use strict'

const RSVP                = require('es6-promise').Promise

const app                 = require('./app.es6')
const groupAddUpdate      = require('./../components/group-add-update.es6')
const personAddUpdate     = require('./../components/person-add-update.es6')
const api                 = require('./../components/api.es6')
const metadata            = require('./../components/metadata.es6')
const roles               = require('./../components/roles.es6')
const gravatar            = require('./../components/gravatar.es6')
const activityUtils       = require('./../components/activity-utils.es6')
const deleteComp          = require('./../components/delete.es6')
const tags                = require('./../components/tags.es6')
const analytics           = require('./../../utils/analytics.es6')
const hubpubsub           = require('./../../utils/hubpubsub.es6')
const modalComponent      = require('./../../utils/modal.es6')
const views               = require('./../views.es6')

let baseGroupId, currentGroupId, groupStructure

const init = (ctx, next)=> {
  baseGroupId       = ctx.params.baseGroupId
  currentGroupId    = (ctx.params.currentGroupId !== undefined) ? ctx.params.currentGroupId : ctx.params.baseGroupId
  document.title    = 'People, organizations & teams | Kotive'

  showPage().then( ()=> {
    const trackingMetadata = { hubUrl: window.location.href }
    analytics.trackEvent('Viewed people', trackingMetadata)
  }).catch( (err)=> {
    console.log(err)
  })
}

const showPage = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    renderCanvas().then( ()=> {
      return fetchAndPrepPeople()
    }).then( (list)=> {
      return addTableData(list)
    }).then( ()=> {
      return renderBreadcrumb()
    }).then( ()=> {
      groupAddUpdate.setupEvents(baseGroupId, currentGroupId, groupStructure)
      personAddUpdate.setupEvents(baseGroupId, currentGroupId, groupStructure)
      return renderPageTitle()
    }).then( ()=> {
      return tags.addTagsToPeopleData(app.groupId, 'tags')
    }).then( ()=> {
      resolve()
    }).catch( (err)=> {
      reject(err)
    })
  })
}

const renderCanvas = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    $('#workarea').replaceWith(views.render("people/people", {
      baseGroupId:        baseGroupId,
      currentGroupId:     currentGroupId
    }))
    resolve()
  })
}

const fetchAndPrepPeople = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    api.send(`/api/group/${currentGroupId}/all?includePersonRoles=true`).then( (data)=> {
      const list = []
      const roles = {}
      const personRoles = {}

      if (data.roles.length > 0) {
        _.each(data.roles, (role)=> {
          roles[role.roleId] = role.label
        })
      }

      if (data.personRoles.length > 0) {
        _.each(data.personRoles, (pRole)=> {
          if (personRoles[pRole.userId] === undefined) personRoles[pRole.userId] = ''
          personRoles[pRole.userId] += `<div class="ui small label">${roles[pRole.roleId]}</div>`
        })
      }

      if (data.people.length > 0) {
        _.each(data.people, (person)=> {
          const personRole = (personRoles[person.id] !== undefined) ? personRoles[person.id] : ''
          const obj = [
            '',
            gravatar.generateHtml(person.email, 32),
            `${person.firstname} ${person.lastname}`,
            person.email,
            person.mobile,
            personRole,
            '',
            'Person',
            `<a href='${currentGroupId}/person/${person.id}/${baseGroupId}'>View profile &rarr;</a>`
          ]
          obj['DT_RowId'] = `${person.id}_${person.firstname} ${person.lastname}`
          obj['DT_RowClass'] = 'row-person'
          list.push(obj)
        })
      }

      if (data.groups.length > 0) {
        _.each(data.groups, (group)=> {
          const obj = [
            '',
            `<img src="${require('../../../images/people-groups-organization.png')}" class="ui tiny circular image" style="max-width: 32px;"/>`,
            `<a href="${baseGroupId}/people/${group.id}">${group.label}</a>`,
            '',
            '',
            '',
            '',
            'Organization',
            `<a href='${baseGroupId}/organization/${group.id}'>View profile &rarr;</a>`
          ]
          obj['DT_RowId'] = `${group.id}_${group.label}`
          obj['DT_RowClass'] = 'row-group'
          list.push(obj)
        })
      }
      resolve(list)
    })
  })
}

const addTableData = (tableData)=> {
  return new RSVP.Promise((resolve, reject)=> {
    $('#workarea img.ui.image.centered').hide()

    $.fn.dataTableExt.sErrMode = 'console'

    const tbl = $('#datatable').DataTable({
      data:           tableData,
      columns:        [
        { title: '', orderable: false, className: 'select-checkbox', width: '10px' },
        { title: '', width: '32px' },
        { title: 'Name'},
        { title: 'Email' },
        { title: 'Mobile' },
        { title: 'Roles' },
        {
          title:      'Tags',
          data:       'tags',
          render: ( data, type, row )=> {
            if ((data !== undefined) && (type === 'display')) {
              data = tags.formatter(data)
            }
            return data
          }
        },
        { title: '', visible: false },
        { title: '', orderable: false }
      ],
      scrollY:        '64vh',
      scrollX:        true,
      scrollCollapse: true,
      select: {
        style:          'multi',
        selector:       'td:first-child',
        info:           false
      },
      paging:         false,
      processing:     true,
      language: {
        search:       'Quickfilter:',
        emptyTable:   '<span class="yellow-strip">No people, organizations or teams available...</span>',
        zeroRecords:  'No matching people, organizations or teams found...',
        infoEmpty:    'Showing 0 to 0 of 0 people, organizations and/or teams',
        info:         'Showing _START_ to _END_ of _TOTAL_ people, organizations and/or teams',
        infoFiltered: '(filtered from a total of _MAX_)'
      },
      buttons:        setButtons(),
      destroy:        true   // https://datatables.net/reference/option/destroy
    })

    tbl.order([ [ 7, 'desc' ], [ 2, 'asc' ] ]).draw()

    tbl.buttons().container().prependTo($('.dataTables_filter'), tbl.table().container())

    deleteComp.enableDeleteButton('people', currentGroupId)

    resolve()
  })
}

const setButtons = ()=> {
  const buttons = []
  buttons.push({
    text:       '<i class="trash alternate outline fitted icon"></i>&nbsp;Delete',
    className:  'ui button icon red inverted btn-delete-js'
  })

  buttons.push({
    text:       '<i class="tags fitted icon"></i>&nbsp;Tag',
    className:  'ui button icon green inverted btn-tag-js'
  })

  buttons.push({
    extend:     'csv',
    fieldSeparator: ';',
    text:       '<i class="icon fitted download"></i>&nbsp;Download',
    className:  'ui button icon green inverted',
    exportOptions: {
      columns:  ':not(:last-child)',
      format: {
        body: function ( data, row, column, node ) {
          return column === 0 ? node.parentNode.id : data
        }
      }
    }
  })

  return buttons
}

const renderBreadcrumb = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    api.send(`/api/group/${baseGroupId}/groupStructure/${currentGroupId}`).then ( (groups)=> {
      groupStructure = groups.groups
      $(`#breadcrumb`).replaceWith(views.render("people/people-breadcrumb", {
        baseGroupId:       baseGroupId,
        groups:            groupStructure.reverse(),
      }))
      resolve()
    })
  })
}

const renderPageTitle = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    const group = groupStructure.reverse()[0]
    $('#page-title-js').html(`${group.label} <a href="#" data-target="modal-group-update"><i class="small write icon"></i></a>`)
    resolve()
  })
}

const setupEvents = ()=> {
}

const onPageExit = (ctx, next)=> {
  $('#reusable-popup.popup').detach().appendTo('body')
  groupAddUpdate.onExit()
  personAddUpdate.onExit()
  next()
}

export { init, setupEvents, onPageExit }
