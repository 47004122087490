'use strict'

const RSVP        = require('es6-promise').Promise

const api         = require('./../components/api.es6')

export const getUserMetadata = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    api.send(`/api/user/metadata`).then( (data)=> {
      const res = _.each(data.userMetaData, (metadataEntry)=> {
        localStorage.setItem(metadataEntry.key, metadataEntry.value)
      })
    }).then( ()=> {
      resolve()
    })
  })
}

export const updateUserMetadata = (metadata)=> {
  return new RSVP.Promise((resolve, reject)=> {
    const opts = { dataType: 'text' }
    api.send(`/api/user/metadata`, 'PUT', metadata, opts).then( (data)=> {
      resolve()
    })
  })
}

export const refreshUserMetadata = (groupId, findByRegexes, forceRefresh=false)=> {
  return new RSVP.Promise((resolve, reject)=> {
    const personProfileGroupId = localStorage.getItem('person-profile-group')

    let found = []

    if ((personProfileGroupId != groupId) || (forceRefresh)) {
      for (let k = 0;k < localStorage.length;k++) {
        if (localStorage.key(k).match(/person-profile-/g)) localStorage.removeItem(localStorage.key(k))
      }
      api.send(`/api/group/${groupId}/metadata`).then( (data)=> {
        localStorage.setItem('person-profile-group', groupId)
        const res = _.each(data.groupMetaData, (metadataEntry)=> {
          const key = metadataEntry.key.replace(/\$groupId/i, groupId)
          const value = metadataEntry.value
          localStorage.setItem(key, value)
          _.each(findByRegexes, (f)=> {
            if (key.match(f)) found.push(JSON.parse(value))
          })
        })
        resolve(_.sortBy(found, 'order'))
      })
    } else {
      for (let k = 0;k < localStorage.length;k++) {
        _.each(findByRegexes, (f)=> {
          if (localStorage.key(k).match(f)) found.push(JSON.parse(localStorage.getItem(localStorage.key(k))))
        })
      }
      resolve(_.sortBy(found, 'order'))
    }
  })
}

export const extractMetadataReportColumns = (taskflowId, property)=> {
  const columns = JSON.parse(localStorage.getItem(`report-columns-${taskflowId}`))
  if (columns !== null) {
    return getPropertyFromColumns(columns, property)
  } else {
    return []
  }
}

export const getPropertyFromColumns = (columns, property)=> {
  const records = columns.map( (item)=> {
    if (! isNaN(item[property])) return parseInt(item[property])
    else return item[property]
  })
  return dedupArray(records)
}

const dedupArray = (arr)=> {
  return arr.filter( (i, p)=> {
    return arr.indexOf(i) == p
  })
}