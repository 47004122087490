'use strict'

const RSVP                = require('es6-promise').Promise
const page                = require('page')

const app                 = require('./app.es6')
const api                 = require('./../components/api.es6')
const personAddUpdate     = require('./../components/person-add-update.es6')
const metadata            = require('./../components/metadata.es6')
const roles               = require('./../components/roles.es6')
const gravatar            = require('./../components/gravatar.es6')
const activityUtils       = require('./../components/activity-utils.es6')
const reportsDelete       = require('./../components/delete.es6')
const profileArchive      = require('./../components/archive.es6')
const todos               = require('./../components/todos.es6')
const widgets             = require('./../components/widgets.es6')
const analytics           = require('./../../utils/analytics.es6')
const hubpubsub           = require('./../../utils/hubpubsub.es6')
const views               = require('./../views.es6')

let groupId, personId, baseGroupId, currentPerson, currentRoles
let isMe = false

const init = (ctx, next)=> {
  groupId       = ctx.params.groupId
  if (ctx.params.personId == 'me') {
    personId      = localStorage.getItem('user-id')
    isMe          = true
  } else {
    personId      = ctx.params.personId
  }
  baseGroupId   = (ctx.params.baseGroupId !== undefined) ? ctx.params.baseGroupId : ctx.params.groupId
  currentPerson = {}
  currentRoles  = {}

  setupEvents()

  showPage().then( ()=> {
    const trackingMetadata = { hubUrl: window.location.href }
    analytics.trackEvent('Viewed person profile', trackingMetadata)
  }).catch( (err)=> {
    console.log(err)
  })
}

const showPage = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    renderCanvas().then( ()=> {
      return renderPerson().then( (person)=> {
        currentPerson = person

        roles.getPersonsRoles(groupId, personId).then( (selectedRoles)=> {
          currentRoles = selectedRoles
        
          $('#person-badge-roles').replaceWith(views.render("people/person-badge-roles", {
            roles:          currentRoles
          }))
          
          personAddUpdate.setupEvents(baseGroupId, groupId, false, currentPerson, currentRoles)
          if (app.isUserAdmin) $('.person-update-js').removeClass('hidden')
        })
      })
    }).then( ()=> {
      todos.showCardForPerson(personId)
      renderArchiveButton()
      return renderFeed()
    }).then( ()=> {
      const findByRegexes  = [/person-profile-[0-9]/g]
      return metadata.refreshUserMetadata(app.groupId, findByRegexes)
    }).then( (groupMetadata)=> {
      renderBreadcrumb()
      return renderProfile(groupMetadata)
    }).then( ()=> {
      resolve()
    }).catch( (err)=> {
      reject(err)
    })
  })
}

const renderCanvas = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    $('#workarea').replaceWith(views.render("people/person", {
      groupId:        app.groupId,
      personId:       personId,
      isMe:           isMe
    }))
    resolve()
  })
}

const renderPerson = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    api.send(`/api/user/group/${app.groupId}/users?ids=${personId}`).then( (people)=> {
      if (people.users.length > 0) {
        const person = people.users[0]
        const displayName = `${person.firstname} ${person.lastname}`
        person.displayName = (displayName == '') ? person.email : displayName
        person.gravatar = gravatar.generateUrl(person.email, 80)
        $('#person-badge').replaceWith(views.render("people/person-badge", {
          person:         person
        }))
        document.title = `${displayName} | Kotive`
        resolve(person)
      } else {
        reject('Person\'s id is invalid.')
      }
    })
  })
}

const renderProfile = (groupMetadata)=> {
  return new RSVP.Promise((resolve, reject)=> {
    $('#person-sheet').empty()
    // $('#person-quicklinks').show()

    const promises = groupMetadata.filter( (gm)=> {
      return (gm.layout == 'widget' || app.permissions[gm.templateActivityId].hasAccess)
    }).map( (metadataEntry)=> {
      if (metadataEntry.layout == 'widget') {
        metadataEntry.divId = `${metadataEntry.label.split(' ').join('-')}`
        $('#person-sheet').append(views.render("widgets/card", {
          id:     metadataEntry.divId,
          label:  metadataEntry.label
        }))
        metadataEntry.data = JSON.stringify(metadataEntry.data)
        metadataEntry.data = metadataEntry.data.replace(/\${groupId}/g, app.groupId).replace(/\${personId}/g, personId)
        metadataEntry.url = metadataEntry.url.replace(/\${groupId}/g, app.groupId).replace(/\${personId}/g, personId)
        return widgets.renderExternalWidget(metadataEntry)
      } else {
        $('#person-sheet').append(views.render("profiles/profile-component", {
          taskflowId: `${metadataEntry.templateActivityId}`,
          label:      `${metadataEntry.label}`,
          layout:     `${metadataEntry.layout}`
        }))
        $('#person-quicklinks').append(views.render("people/quicklinks", { label: `${metadataEntry.label}`, link: `${metadataEntry.templateActivityId}` }))
        return renderProfileComponent(metadataEntry)
      }
    })

    RSVP.all(promises).then( (components)=> {
      $('table.dataTable').DataTable({
        searching:      false,
        paging:         false,
        info:           false,
        scrollY:        '64vh',
        scrollX:        true,
        scrollCollapse: true,
        select: {
          style:          'multi',
          selector:       'td:first-child',
          info:           false
        },
        language: {
          search:       'Quickfilter:',
          emptyTable:   '<span class="yellow-strip">No information is available...</span>'
        }
      })
      resolve()
    }).catch( (error)=> {
      console.error(error)
      hubpubsub.publish('notification.show', { kind: 'negative', message: 'Sorry, something went wrong... Please refresh your browser and try again.'})
      reject(error)
    })
  })
}

const renderProfileComponent = (component)=> {
  return new RSVP.Promise((resolve, reject)=> {
    const query = {
      "templateActivityIds": metadata.getPropertyFromColumns(component.columns, 'templateActivityId'),
      "templateFieldIds": metadata.getPropertyFromColumns(component.columns, 'templateFieldId')
    }
    // const fromTimestamp = localStorage.getItem('date-range-start')
    // if (fromTimestamp !== null) query.fromTimestamp = fromTimestamp
    query.fromTimestamp = 0
    // const toTimestamp   = localStorage.getItem('date-range-end')
    // if (toTimestamp !== null) query.toTimestamp = toTimestamp

    if (component.linkedPermissionFields !== undefined) query.linkedPermissionFields = component.linkedPermissionFields
    query.dynamicLookups  = true
    query.restrictToGroup = true

    if (component.filterFields !== undefined) {
      query.filterFields = {}
      if (component.filterFields.must !== undefined) {
        _.each(component.filterFields.must, (filter, fi)=> {
          Object.keys(filter).forEach((m)=> {
            component.filterFields.must[fi][m] = component.filterFields.must[fi][m].map( (v, k)=> {
              let value = v.toString().replace(/\${personId}/g, personId)
              value = value.replace(/\${groupId}/g, app.groupId)
              return value
            })
          })
        })
        query.filterFields.must = component.filterFields.must
      }
    }

    api.send(`/hub/reports/${app.groupId}/${component.templateActivityId}`, 'POST', query).then ( (activities)=> {
      activityUtils.prepareActivities(app.groupId, component.templateActivityId, activities, component.columns, component.componentActions).then( ([preppedActivities, latestTS])=> {
        activityUtils.reorderFieldsInActivities(component.columns, preppedActivities).then( (reorderedActivities)=> {
          if (!app.permissions[component.templateActivityId].start) delete component.componentActions['add']
          if (!app.permissions[component.templateActivityId].continue) delete component.componentActions['continue']
          if (!app.permissions[component.templateActivityId].delete) delete component.componentActions['delete']

          $(`#profile-${component.templateActivityId}`).replaceWith(views.render("profiles/profile-component", {
            groupId:           app.groupId,
            taskflowId:        component.templateActivityId,
            personId:          personId,
            component:         component,
            activities:        reorderedActivities
          }))

          if (app.permissions[component.templateActivityId].delete) reportsDelete.enableDeleteButton('activities', app.groupId, component.templateActivityId)

          resolve(preppedActivities)
        })
      })
    })
  })
}

const renderBreadcrumb = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    if (isMe) {
      resolve()
    } else {
      api.send(`/api/group/${baseGroupId}/groupStructure/${app.groupId}`).then ( (groups)=> {
        api.send(`/api/group/${app.groupId}/people`).then ( (people)=> {
          $(`#breadcrumb`).replaceWith(views.render("people/person-breadcrumb", {
            groupId:           app.groupId,
            baseGroupId:       baseGroupId,
            currentPerson:     currentPerson,
            people:            _.sortBy(people.people, 'firstname'),
            groups:            groups.groups.reverse(),
            gravatar:          gravatar
          }))

          $('#breadcrumb .ui.dropdown').dropdown({
            action:           'hide',
            onChange:         (value, text, $selectedItem)=> {
              $('#breadcrumb .ui.dropdown').addClass('disabled')
              setTimeout( ()=> {
                page(`/${app.groupId}/person/${value}/${baseGroupId}`)
              }, 500)
            }
          })
          resolve()
        })
      })
    }
  })
}

const renderFeed = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    // $(`#feed-timeline`).replaceWith(views.render("feed-timeline", {}))
    resolve()
  })
}

const refreshBadge = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    renderPerson().then( (person)=> {
      currentPerson = person

      roles.getPersonsRoles(groupId, personId).then( (selectedRoles)=> {
        currentRoles = selectedRoles
      
        $('#person-badge-roles').replaceWith(views.render("people/person-badge-roles", {
          roles:          currentRoles
        }))
      }).then( ()=> {
        personAddUpdate.setupEvents(baseGroupId, groupId, false, currentPerson, currentRoles)
        if (app.isUserAdmin) $('.person-update-js').removeClass('hidden')
        renderBreadcrumb()
        renderFeed()
        resolve()
      })
    })
  })
}

const renderArchiveButton = ()=> {
  return new RSVP.Promise((resolve, reject)=> {
    const metadata = localStorage.getItem('archive-person')
    if (metadata !== null) {
      $('#person-actions').append(`<button class="ui small green inverted button btn-archive-js"><i class="archive icon"></i> Archive</button>`)
      profileArchive.enableArchiveButton('person', baseGroupId, groupId, personId, metadata)
    }
    resolve()
  })
}

const setupEvents = ()=> {
  const refreshPersonBadgeSubscription = hubpubsub.subscribe('person.refreshBadge', (data)=> {
    $('#person-badge').html(`<div class="content text-center"><img class="ui centered image" src="${require('../../../images/loading.gif')}"/></div>`)
    refreshBadge()
  })
}

const onPageExit = (ctx, next)=> {
  todos.unsubscribeFromRealtimeChannel()
  $('#reusable-popup.popup').detach().appendTo('body')
  personAddUpdate.onExit()
  next()
}

export { init, setupEvents, onPageExit }