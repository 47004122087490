'use strict'

const RSVP                = require('es6-promise').Promise

const views               = require('./../views.es6')

export const init = (ctx, next)=> {
  document.title = 'Hub | Kotive'
  const appConfiguration = JSON.parse(localStorage.getItem('app-config'))

  $('#workarea').replaceWith(views.render('dashboard/dashboard', {
    menu:         appConfiguration.menu
  }))
}