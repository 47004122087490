'use strict'

const api                 = require('./../components/api.es6')
const hubpubsub           = require('./../../utils/hubpubsub.es6')
const modalComponent      = require('./../../utils/modal.es6')
const views               = require('./../views.es6')

const upsert = (baseGroupId, currentGroup)=> {
  const id          = $('#reusable-modal form [name=group-id]').val()
  const id_parent   = $('#reusable-modal form [name=group-id_parent]').val()
  const label       = $('#reusable-modal form [name=group-label]').val()

  if (label == '') {
    $('#reusable-modal .content .kotive-validation-message').removeClass('hidden')
    $('#reusable-modal .content').scrollTop(0)
    return false
  }

  const hierarchy = {
    "company":      "application",
    "application":  "organization",
    "organization":  "organization"
  }

  const action = (parseInt(id) != -1) ? 'updated' : 'added'
  const group = {
    "id": parseInt(id),
    "label": label,
    "id_parent": parseInt(id_parent),
    "extend_id": -1,
    "status": "active",
    "level": hierarchy[currentGroup.level]
  }

  api.send(`/api/group/${currentGroup.id}`, 'POST', group).then ( (data)=> {
    hubpubsub.publish('notification.show', { kind: 'green', message: `The organization/team has been ${action}.`})

    if (action == 'added') {
      const obj = [
        '',
        `<img src="${require('../../../images/people-groups-organization.png')}" class="ui tiny circular image" style="max-width: 32px;"/>`,
        `<a href='${baseGroupId}/people/${data.group.id}'>${data.group.label}</a>`,
        '',
        '',
        'Organization',
        `<a href='${baseGroupId}/organization/${data.group.id}'>View profile &rarr;</a>`
      ]
      obj['DT_RowId'] = `${data.group.id}_${data.group.label}`
      obj['DT_RowClass'] = 'row-group'
      $('.dataTable').DataTable().row.add(obj).draw()

    } else if (action == 'updated') {
      onExit()
      hubpubsub.publish('organization.refreshBadge', data.group)
    }

    const plusOrMinus = (action == 'added') ? '➕' : '➖'
    api.send(`/apiv3/events/${baseGroupId}/log`, 'POST', [{
      key: `group-${action}`,
      label: `{user.firstname} {user.lastname} ${action} ${data.group.label} to {group.label}`,
      icon: `${plusOrMinus}🏢` 
    }], { dataType: 'text' })
  })
}

const setupEvents = (baseGroupId, currentGroupId, groupStructure)=> {
  $('body').on('click', "[data-target='modal-group-new'], [data-target='modal-group-update']", (e)=> {
    e.preventDefault()

    const isNew = ($(e.target).data('target') == 'modal-group-new') ? true: false
    let currentGroup = {}

    modalComponent.resizeHeight('#reusable-modal')

    $('#reusable-modal').modal({
      duration: 10,
      selector: {
        deny:       '.close-modal, .actions .close-modal',
        approve:    '.actions .positive, .actions .approve, .actions .ok'
      },
      onVisible: ()=> {
        currentGroup = _.findWhere(groupStructure, { id: parseInt(currentGroupId)} )
        const levels = {'company': 'Maker account', 'application': 'App', 'organization': 'Organization/team'}
        
        const header = isNew ? `Add a new ${levels[currentGroup.level]}` : `Update the ${levels[currentGroup.level]}`
        $('#reusable-modal .header').html(header)
        
        $('#reusable-modal .content').html(views.render("people/group-add-update", {
          group: {
            id:         isNew ? -1 : currentGroup.id,
            id_parent:  isNew ? parseInt(currentGroupId) : currentGroup.id_parent,
            label:      isNew ? '' : currentGroup.label
          }
        }))
      },
      onApprove: ()=> {
        return upsert(baseGroupId, currentGroup)
      },
      onHidden: ()=> {
        modalComponent.revertUI('#reusable-modal')
      }
    }).modal('show').modal('refresh')
  })
}

const onExit = ()=> {
  $('body').off('click', "[data-target='modal-group-new'], [data-target='modal-group-update']")
}

export { setupEvents, onExit }