'use strict'

const config    = require('./../config.es6').getConfig()

export const extractFieldPattern = (patternStr)=> {
  const pattern = {}

  pattern.kind          = patternStr.substring(0, 3).toString()
  pattern.selections    = extractPatternOptions(patternStr, pattern.kind)
  pattern.default       = extractDefault(patternStr, pattern.kind)

  return pattern
}

const extractPatternOptions = (patternStr, kind)=> {
  if(kind === 'mse' || kind === 'sse') {
    const start = patternStr.indexOf('[') + 1
    const end   = patternStr.indexOf(']')

    if(start === -1 || end === -1) return []

    const optsString = patternStr.substring(start, end)

    return optsString.split('^').map((opt)=> {
      return extractKeyValuePair(opt)
    })
  }
  else return null
}

const extractDefault = (patternStr, kind)=> {
  if(kind === 'sse' || kind === 'mse') {
    // const start = patternStr.nthOccurrenceIndex('[', 2) + 1
    // const end   = patternStr.nthOccurrenceIndex(']', 2)
    const start = patternStr.indexOf('][') + 2
    const end   = patternStr.lastIndexOf(']')

    if(start === -1 || end === -1) return null
    else {
      const opt = patternStr.substring(start, end)

      if(opt === '') return kind === 'mse' ? [] : null
      else {
        if(kind === 'mse') {
          return opt.split('^').map((opt)=> {
            return extractKeyValuePair(opt)
          }).map((opt)=> { return opt.key })
        }
        else return extractKeyValuePair(opt)
      }
    }
  } else {
    const start = patternStr.indexOf('[') + 1
    const end = patternStr.lastIndexOf('][]')

    if(start === -1 || end === -1) return null
    else return patternStr.substring(start, end)
  }
}

const extractKeyValuePair = (opt)=> {
  if(opt.indexOf(':') === -1) {
    const optVal = decode(opt)
    return {key: optVal, value: optVal}
  } else {
    const [key, value] = opt.split(':')
    return {key: decode(key), value: decode(value)}
  }
}

const decode = (str)=> {
  Object.keys(config.charEncodings).forEach( key => {
    const char        = key
    const encodedChar = config.charEncodings[key]
    const regex       = new RegExp(encodedChar, 'g')

    str = str.replace(regex, char)
  })
  return str
}